<template>
  <div class="cxKcb">
    <heade></heade>
    <div class="cen_wrap">
      <div class="cen_box"></div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import "@css/foot.css";
export default {
  components: {
    heade,
    foot,
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
};
</script>

<style>
.cxKcb .cen_wrap {
  width: 100%;
  height: 1436px;
  background: url(../../assets/images/kecb.jpg);
  background-position: center 0;
  background-size: cover;
}
.cxKcb .cen_box {
  width: 800px;
  height: 716px;
  position: relative;
  top: 480px;
  left: 534px;
}
</style>